import React, { Component } from "react";
import {
  LoginContainer,
  LoginForm,
  LoginInput,
  LoginImage,
  Button
} from "./style/style";
import { colour } from "../../style/colour";
import toastr from "toastr";
import axios from "axios";
import API from "../../api/constant";

class Login extends Component {
  state = {
    userInput: {
      email: "",
      password: ""
    }
  };

  doLogin = e => {
    e.preventDefault();
    const { email, password } = this.state;
    if (email === "" && password === "") {
      toastr.error("This field can't be empty");
      return false;
    }

    axios({
      url: `${API.baseUrl}/admin/login`,
      method: "POST",
      data: this.state.userInput
    })
      .then(response => {
          console.log(response.data);
        
        if (response.data.status === 200) {

          localStorage.setItem('CallerView-XXX', response.data.data.token);

          window.location.reload()
          console.log('successful')
        
        }else{
          console.log('failed')

        }
      })
      .catch(error => {
        // console.log(error.response.data);
        toastr.error(error.response.data.message);
      });
  };

  handleChangeEvent = e => {
    const { userInput } = this.state;

    this.setState({
      userInput: {
        ...userInput,
        [e.target.name]: e.target.value
      }
    });
  };

  render() {
    const { email, password } = this.state.userInput;

    return (
      <LoginContainer>
        <LoginImage src={require("../../assets/imgs/logo.svg")} />

        <LoginForm onSubmit={this.doLogin}>
          <LoginInput
            type="text"
            placeholder="email address"
            name="email"
            onChange={this.handleChangeEvent}
            value={email}
          />
          <LoginInput
            type="password"
            placeholder="password"
            name="password"
            onChange={this.handleChangeEvent}
            value={password}
          />

          <Button
            width="368px"
            height="50px"
            fontColor={colour.white}
            bgColor={colour.secondary}
          >
            Login
          </Button>
        </LoginForm>
      </LoginContainer>
    );
  }
}

export default Login;
