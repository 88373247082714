import React, { Component } from "react";

// bootstrap and custom css
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "react-js-pagination";

import "./tableList.css";

// styled-component
import {
  Button,
  FormContainer,
  RowRight,
  Row,
  FormUpload,
  ImageForm,
  FormLabel,
  FormInput,
  Checkbox,
  Column,
  TableStyle,
  TableRow,
  TableFooterList,
  Form,
  MiniContent
} from "../../../../style/init";
import { colour } from "../../../../style/colour";
import {SearchStyle} from '../search/search';

// axios
import axios from "axios";
import API from "../../../../api/constant";

// select and option component
import VideoListSelect from "../select/select";
import VideoListOption from "../select/option";

// video preview library
import { Player } from "video-react";
import ReactPlayer from "react-player";

import toastr from "toastr";

// dummy data
// import { video } from "./data";

// modal for the edit alone
import ModalVideoList from "../modal";


class TableList extends Component {
  constructor() {
    super();

    this.state = {
      content: null,
      columns: null,
      checkoxes: null,
      modal: false,
      modalText: "",
      style:false,

      removeStatus: false,
      categories: [],
      regions: [],
      selected: [],
      activeRow: null,

      videoDetails: {
        name: "",
        link: "",
        category: "",
        region: "",
        releaseDate: "",
        image: ""
      },
      loading: false,
      loadingProgress: false,
      upProgress: 0,
      isUploading: false,
      imageText: false,
      activePage: 1,
      totalItemsCount: null
    };
    this.uploadInput = React.createRef();
    this.imageInput = React.createRef();
  }

  componentDidMount() {
    // fetch all videos
    // this.fetchVideos();
    this.fetchVideoPerPage()
    // fetch all category
    this.fetchAllCategory();

    // fetch all region
    this.fetchAllRegion();
  }

  componentWillMount() {

    // set state of columns
    const columns = [
      "Name",
       "Category",
       "Video",
       "Region",
       "Release Date",
       "Image",
       "Actions"
    
    ];

    this.setState({
      columns: columns,
    });
  }
  fetchVideoPerPage=()=> {
    const {activePage} = this.state;
    const token = localStorage.getItem("CallerView-XXX");

    axios({
      url: `${API.baseUrl}/video/view/${activePage}?limit=6`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        let result = res.data.data.video;
        let num =res.data.data.count;
        
        this.setState(
          {
            content: result,
            totalItemsCount: num
          },
          () => {
            localStorage.setItem("videos", JSON.stringify(this.state.content));
          }
        );
      }).catch(err => toastr.error(err));
   
  }

 
  // get all category
  fetchAllCategory = () => {
    const token = localStorage.getItem("CallerView-XXX");

    axios({
      url: `${API.baseUrl}/category`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        this.setState({ categories: res.data.data });
      })
      .catch(err => {
        toastr.error(err);
      });
  };

  // get all rgion
  fetchAllRegion = () => {
    const token = localStorage.getItem("CallerView-XXX");

    axios({
      url: `${API.baseUrl}/region`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        let newArray = [...new Set(res.data.data.regions)];

        this.setState({
          regions: newArray
        });
      })
      .catch(err =>toastr.error(err));
  };

  handlePageChange =(pageNumber)=> {
    console.log(`active page is ${pageNumber}`);

    this.setState({activePage: pageNumber},
      ()=>{
        this.fetchVideoPerPage()
      });

    
  }

  // hanle change by category
  handleCategoryChange = e => {
    const value = e.target.value;
    let videos = JSON.parse(localStorage.getItem("videos"));

    if (value === "all") {
      this.setState({ content: videos });
    } else {
      videos = videos.filter(
        video => video.category.toLowerCase() == value.toLowerCase()
      );
      this.setState({ content: videos });
    }
  };

  // handle change by region
  handleRegionChange = e => {
    const value = e.target.value;

    let videos = JSON.parse(localStorage.getItem("videos"));

    if (value === "all") {
      this.setState({ content: videos });
    } else {
      videos = videos.filter(
        video => video.region.toLowerCase() === value.toLowerCase()
      );
      this.setState({ content: videos });
    }
  };

  handleSearchFilter = e =>{
    const token = localStorage.getItem("CallerView-XXX");
    const value = e.target.value;

    axios({
      url: `${API.baseUrl}/video/search?q=${value}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      let videos = res.data.data;
      this.setState({content:videos})

    }).catch(err =>{
      toastr.error(err)
    })
    
  }
   // yes btn action
   statusYes = () => {
    this.setState({
      modal: false,
      modalText: ""
    });
    // call the remove method here
    this.deleteRow(this.state.activeRow);
  };

  // No btn action
  statusNo = () => {
    this.setState({
      modal: false,
      activeRow: null
    });
  };
  // onClick of delete btn
  openModal = row => {
    this.setState({
      activeRow: row._id,
      modal: true,
      modalText: "Are you sure you want to continue this process?"
    }); 
  };


  deleteRow = (index) =>{
    const { content } = this.state;
    let arr = [];
    let result = arr[0];

    const token = localStorage.getItem("CallerView-XXX");

    for(let i =0; i< content.length; i++) {
      if(content[i]._id ===  index ){

        arr.push(i);
        content.splice(i, 1);
      
      }
    }

    axios({
      url: `${API.baseUrl}/video/delete/${index}`,
      method: "DELETE",
      Data: content[result],
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      this.setState({
        content

      });
      toastr.success('Video Successfully Deleted')
    });
   
  }

  // display row inside modal
  editRow = index => {
    // set modal open with modal Title
    this.setState({
      modal: true,
      modalText: "Edit a Single Video",
      style: !this.state.style
    });

    // call the update method with the row passed
    this.getVideoRow(index);
  };


  // update a row
  getVideoRow = row => {
    // set the modal to be the values from the row
    let videos = JSON.parse(localStorage.getItem("videos"));
    const {content} = this.state;

    let id = content[row]._id
    const { videoDetails } = this.state;
    let result;

    for (let i = 0; i < videos.length; i++) {
      if (videos[i]._id === id) {
        result = videos[i];
      }
    }

    this.setState({
      activeId: id,
      videoDetails: {
        ...videoDetails,
        name: result.name,
        link: result.link,
        image: result.image,
        category: result.category,
        region: result.region,
        releaseDate: result.releaseDate
      }
    });
  };

  // handle change event for the input modal
  handleChangeRow = event => {
    const { videoDetails } = this.state;

    this.setState({
      videoDetails: {
        ...videoDetails,
        [event.target.name]: event.target.value
      }
    });
  };
  
  // pause video player
  pauseVideo =()=>{
    this.refs.player.pause();
  }

  // handle change event for the image modal
  onChangeImage = e => {
    // console.log("hi");
    const selectedImage = e.target.files[0];
    let img = new FormData();
    img.append("video", selectedImage);

    const token = localStorage.getItem("CallerView-XXX");
    const { videoDetails } = this.state;

    this.setState({
      isUploading: true,
      imageText: true
    });

    axios({
      url: `${API.baseUrl}/video/upload`,
      method: "POST",
      data: img,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    })
      .then(json => {
        // console.log(json.data)

        if (json.data.status === 200) {
          //works
          this.setState({
            videoDetails: {
              ...videoDetails,
              image: json.data.url
            },
            imageText: false,
            isUploading: false
          });
          return false;
        }
      })
      .catch(err => {
        toastr.error(err);
      });
  };

  // handleChange for the video modal
  onChangeVideo = e => {
    const selectedFile = e.target.files[0];

    let f = new FormData();
    f.append("video", selectedFile);
    
    if(f !== ''){
      // pause the video
      this.pauseVideo();

    }

    const token = localStorage.getItem("CallerView-XXX");
    const { videoDetails } = this.state;

    this.setState({
      loadingProgress: true,
      isUploading: true,
    });

    axios({
      url: `${API.baseUrl}/video/upload`,
      method: "POST",
      data: f,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        this.setState({
          upProgress: percentCompleted
        });

        if (percentCompleted === 100) {
          this.setState({
            upProgress: 0,
            loadingProgress: false
          });
        }
      }
    })
      .then(json => {
        if (json.data.status === 200) {
          //works
          this.setState({
            videoDetails: {
              ...videoDetails,
              link: json.data.url
            },
            isUploading: false

          });
          return false;
        }
      })
      .catch(err => {
        toastr.error(err);
      });
  };

  // when complete is clicked
  updateVideo = e => {
    // e.preventDefault();

    const { videoDetails, activeId, content } = this.state;
    const token = localStorage.getItem("CallerView-XXX");

    if (
      videoDetails.name.trim() === "" ||
      videoDetails.category.trim() === "" ||
      videoDetails.region.trim() === "" ||
      videoDetails.releaseDate.trim() === "" ||
      videoDetails.image === "" ||
      videoDetails.link === ""
    ) {
      toastr.error("Ensure that the form isn't empty...");
      return false;
    }

    this.setState({
      loading: true
    });

    axios({
      url: `${API.baseUrl}/video/single/${activeId}`,
      method: "PUT",
      data: videoDetails,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        let response = res.data.data.videos;

        for (let i = 0; i < content.length; i++) {
          if (content[i]._id === activeId) {
            content[i] = response;
          }
        }

        this.setState({
          content,
          modal: false,
          loading: false
        });
      })
      .catch(error => {
        toastr.error(error);

        this.setState({
          loading: false
        });
      });
  };

  activeAdvert = id => {
    const token = localStorage.getItem("CallerView-XXX");
    let payload = {
      status: true,
      videoID: id
    }
    this.setState({ loading: true })
    axios({
      url: `${API.baseUrl}/video/active`,
      method: "PUT",
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
        toastr.success("Active advert successfully set")
        this.setState({
          loading: false
        });
    })
    .catch(error => {
        toastr.error(error);
        this.setState({
          loading: false
        });
    });
  }

  // dismiss modal
  dismissable = () => {
    this.setState({ modal: false , activeId:'', style: false});
  };

  render() {

    let id;
    const {
      categories,
      regions,
      content,
      columns,
      modal,
      modalText,
      // checkboxes,
      // Footer,
      videoDetails,
      loading,
      loadingProgress,
      style
    } = this.state;

    let children, className;
    // css style
    className = videoDetails.link ? "video-active" : null;


    // video Progress bar
    const videoProgressBar = loadingProgress ? (
       <p className="video-text">Please wait...</p>
    ) : null;

    // category options
    const categoryContent =
      categories &&
      categories.map(category => (
        <VideoListOption value={category.name} key={category._id}>
          {category.name}
        </VideoListOption>
      ));

    // region options
    const regionContent =
      regions &&
      regions.map((region, i) => (
        <VideoListOption value={region} key={i}>
          {region}
        </VideoListOption>
      ));
    
    if (modal && style) {
      children = (
        <>
          {/* modal children for edit here */}
          <div className="text-title">
            <p>{modalText}</p>
          </div>

          <Row>
            <FormContainer width="70%">
              {/* video name input */}
              <Form>
                <FormLabel>Video Name</FormLabel>
                <FormInput
                  width="100%"
                  height="60px"
                  type="text"
                  name="name"
                  onChange={this.handleChangeRow}
                  value={videoDetails.name}
                />
              </Form>

              {/* video link */}
              <Form>
                <FormLabel>URL/Link</FormLabel>
                <FormInput
                  width="100%"
                  height="60px"
                  type="text"
                  readOnly
                  value={videoDetails.link}
                />
              </Form>

              {/* category select field */}
              <Form>
                <FormLabel>Category</FormLabel>

                <VideoListSelect
                  style={{ width: "100%", height: "60px" }}
                  onChange={this.handleChangeRow}
                  name="category"
                >
                  <VideoListOption value={videoDetails.category}>
                    {" "}
                    {videoDetails.category}{" "}
                  </VideoListOption>

                  {categoryContent}
                </VideoListSelect>
              </Form>

              {/* region select field */}
              <Form>
                <FormLabel>Region</FormLabel>

                <VideoListSelect
                  style={{ width: "100%", height: "60px" }}
                  name="region"
                  onChange={this.handleChangeRow}
                >
                  <VideoListOption value={videoDetails.region}>
                    {" "}
                    {videoDetails.region}{" "}
                  </VideoListOption>

                  {regionContent}
                </VideoListSelect>
              </Form>

              {/* date input*/}
              <Form>
                <FormLabel>Release Date</FormLabel>
                <FormInput
                  width="100%"
                  height="60px"
                  type="date"
                  name="releaseDate"
                  onChange={this.handleChangeRow}
                  value={videoDetails.releaseDate}
                />
              </Form>

              <ImageForm>
                <FormUpload
                  height="65px"
                  className="img-upload"
                  onClick={_ => {
                    !this.state.isUploading && this.imageInput.current.click();
                  }}
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={this.onChangeImage}
                    accept="image/*"
                    ref={this.imageInput}
                  />

                  {!this.state.imageText ? (
                    <p className="img-text">Upload image</p>
                  ) : (
                    <p className="img-text">Loading...</p>
                  )}
                </FormUpload>

                <div className="img-input">
                  <FormLabel id="img-url">Image URL</FormLabel>
                  <FormInput
                    type="text"
                    width="100%"
                    height="40px"
                    readOnly
                    value={videoDetails.image}
                  />
                </div>
              </ImageForm>
            </FormContainer>

            <FormContainer
              width="25%"
              style={{ position: "relative", top: "15px" }}
            >
              <FormUpload
                height="305px"
                onClick={_ => {
                  !this.state.isUploading && this.uploadInput.current.click();
                }}
              >
                <Player
                  ref="player"
                  fluid="false"
                  className={className}
                  playsInline
                  src={videoDetails.link}
                >
                </Player>

                <input
                  type="file"
                  onChange={this.onChangeVideo}
                  style={{ display: "none" }}
                  accept="video/*"
                  ref={this.uploadInput}
                />

                {videoProgressBar}
              </FormUpload>

              
            </FormContainer>
          </Row>

          <RowRight>
            <Button
              width="25%"
              height="60px"
              fontColor={colour.white}
              bgColor={colour.secondary}
              onClick={this.updateVideo}
            >
              {loading && <i className="fas fa-spinner fa-spin " />}
              COMPLETE
            </Button>
          </RowRight>
        </>
      );
    }else if(modal && !style){
      children =(
        <>
          <div className="text">
            <p>{modalText}</p>
          </div>

          <div className="group-btn">
            <Button
              height="60px"
              id="margin-left"
              width="123px"
              fontColor={colour.white}
              bgColor={colour.secondary}
              onClick={this.statusYes}
            >
              YES
            </Button>

            <Button
              height="60px"
              width="123px"
              fontColor={colour.white}
              bgColor={colour.gray}
              onClick={this.statusNo}
            >
              NO
            </Button>
          </div>
        </>

      )
    }

    return (
      <>
          
        <MiniContent width="100%">

            <div className="top" id="search-section">
                  <h2 style={{marginBottom: '32px'}}>ALL VIDEOS</h2>

                <div className="main-content">
                    <SearchStyle type="text" placeholder="Search"  onChange={this.handleSearchFilter}/>

                    <div className="search-group">
                        <VideoListSelect
                        onChange={this.handleCategoryChange}
                        className="selectedItem1"
                        >
                        <option defaultValue="" disabled selected hidden>
                            {" "}
                            Select category{" "}
                        </option>
                        <VideoListOption value="all">All</VideoListOption>

                        {categoryContent}
                        </VideoListSelect>

                        <VideoListSelect
                        className="selectedItem2"
                        onChange={this.handleRegionChange}
                        >
                        <option defaultValue="" disabled selected hidden>
                            {" "}
                            Select Region{" "}
                        </option>

                        <VideoListOption value="all">All</VideoListOption>

                        {regionContent}
                        </VideoListSelect>
                    </div>

             </div>
            </div>
          

            {/* Table */}
            <div className="content">
              <TableStyle>
                <Column>
                  <TableRow id="header">
                    
                    {columns.map((head, index) => {
                      
                      if(index === 1){
                        id = `head-${index}`
                      }else if(index === 2){
                        id = `head-${index}`
                      }else if(index === 6){
                        id= `head-${index}`
                      }
                      else{
                        id = ''
                      }
                      
                      return (
                        <p key={index} className="headers" id={id}>
                          {head}
                        </p>
                      );
                    })}
                  </TableRow>
                  <hr />
                  
                  {content && content.map((row, index) => {

                    return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <Checkbox
                              type="checkbox"
                              // checked={uploadables.includes(index)}
                              // onChange={this.check.bind(this, index)}
                            />

                            <p>{row.name}</p>
                            <p className="link">{row.category}</p>
                            <p className="row-video">
                           
                              <ReactPlayer 
                              url={row.link} 
                              // playsinline
                              controls
                              className="tablePlayer"/>

                            </p>
                            <p className="row-region">{row.region}</p>
                            <p className="row-date">{row.releaseDate}</p>
                            <p className="row-image">
                            <img src={row.image} alt="rowLink" style={{maxWidth: '100px'}}/></p>

                            <span id="group-icon" style={{left: '-25px'}}>
                              <button onClick={this.openModal.bind(this, index)}>
                                <img
                                  src={require("../../../../assets/imgs/actions/deleteIcon.svg")}
                                  alt="delete-icon"
                                />
                              </button>
                              <button onClick={this.editRow.bind(this, index)}>
                                <img
                                  src={require("../../../../assets/imgs/actions/editIcon.svg")}
                                  alt="edit-icon"
                                />
                              </button>
                              {row.category === "advert" ? 
                              <button onClick={()=>this.activeAdvert(row._id)}>
                                <img
                                  src={require("../../../../assets/advert.svg")}
                                  alt="edit-icon"
                                />
                              </button> : null }
                            </span>
                          </TableRow>
                          <hr />
                        </React.Fragment>
                      );

                  })

                }
                <TableFooterList>
                <div className="row-1"></div>
                <div className="row-2">
                  <Pagination 
                     activePage={this.state.activePage}
                     itemsCountPerPage={6}
                     totalItemsCount={this.state.totalItemsCount}
                   
                     activeLinkClass={'page-link'}
                     onChange={this.handlePageChange.bind(this)}
                   />
                </div>
                  
                </TableFooterList>
               
                </Column>
              </TableStyle>
            </div>

        </MiniContent>


        <ModalVideoList
          visible={modal}
          dismiss={this.dismissable}
          children={children}
          styles={style}
        />
        
      </>
    );
  }
}
export default TableList;
